import './fabrication_workflow.scss';

// External Libs
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Axios from 'axios';
import React, { Component } from 'react';
import Select from 'react-select';

// Internal Components
import SessionExpire from '../../../../../common/session_expire';
import { handleHttpRequestError } from '../../../../../common/error';
import { convertDateWithFormat, convertDate, setTokenHeader, removeIreqIdInitialNumber, configLightBox } from '../../../../../common/functions';
import Modal from '../../../../../components/modal/modal';
import { UserPermissionsContext } from '../../../../../context/user_permission';
import ThumbnailURLInterface from '../../../../../components/thumbnail/thumbnail_url_interface';

// Contants
import {
  SUCCESS,
  FAILURE,
  FABRICATION_STATUSES,
  COURSE_OF_ACTION,
  PROCESS_STEP,
  LOWER,
  UPPER,
  LOCATION_OPTION,
  LOCATION_NUMBERS,
  DEFAULT_BREAK_LOCATION,
  COURSE_OF_ACTION_ONLY_REDESIGN,
  WIRE_FABRICATION_LOG_STATUSES,
  EDIT_STATUSES,
  PERMISISON_ROLES,
} from './constants';
import {
  WS_WIRE_FABRICATION_STATUS,
  WS_WIRE_FABRICATION_REINITIATED_STATUS,
  WS_COMPLETE_FABRICATION_STATUS,
  WS_REDESIGN_FABRICATION_STATUS,
  WS_WIRE_FABRICATION_RESOLVED_STATUS,
} from '../constants';

class FabricationWorkflow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      refresh: false,
      action_modal: "",
      modal_in_progress_text: '',
      modal_in_progress: '',
      permitted_users: [],
      modal: null,
      upload_modal: null,
      remove_upload: null,
      refreshThumbnail: 0,
    };
    this.displayWireItens = this.displayWireItens.bind(this);
    this.onModalConfirm = this.onModalConfirm.bind(this);
    this.fetchUpdateIRFile = this.fetchUpdateIRFile.bind(this);
  };

  componentDidMount() {
    this.updateItems();
  };
  
  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.updateItems();
    };
    if (prevProps.ws_data.ir_files != this.props.ws_data.ir_files) {
      this.setState({ refreshThumbnail: this.state.refreshThumbnail + 1 });
    }
  };

  /**
 * Updates an item by modifying its status and break locations.
 *
 * @param {object} item - The item to be updated.
 * @returns {object} Returns the updated item.
 */
  updateItem(item) {
    const updatedItem = { ...item };
    updatedItem.status = updatedItem.status ? updatedItem.status : SUCCESS;

    const breakLocations = updatedItem.break_locations
      ? JSON.parse(updatedItem.break_locations)
      : [{ ...DEFAULT_BREAK_LOCATION }];

    updatedItem.break_locations = breakLocations.map(breakLocation => {
      const updatedBreakLocation = { ...breakLocation };
      updatedBreakLocation.uuid = uuidv4();
      return updatedBreakLocation;
    });
    return updatedItem;
  };

  /**
 * Updates all items in the component state.
 * Filters the items based on certain conditions and applies the updateItem function to each item.
 * Updates the component state with the updated items.
 */
  updateItems() {
    const updatedItems = this.props.items
      .filter(item => item.current_rev && item.group === 'smartwire')
      .map(item => {
        return this.updateItem(item)
      });
  
    this.setState({ items: updatedItems });
  };

  /**
 * Handles the update of an item's field by making a PATCH request to the server.
 *
 * @param {object} item - The item to update.
 * @param {object} fields - The fields to be updated.
 */
  updateItemFieldChanged(item, fields) {
    setTokenHeader()
    const that = this;
    const data = {ireq_wire_selection_id: item.id, fields};
    Axios.patch(`/apiv3/ireqautomationstatus/${this.props.ireq_id}/ws/update_field`, {...data})
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          that.setState({
            action_modal: '',
            modal_in_progress: false,
          });
        }
      });
  };

  /**
 * Handles the change of an item's status.
 *
 * @param {object} selectedOption - The selected option representing the new status.
 * @param {number} index - The index of the item in the items array.
 */
  handleStatusChange = (selectedOption, index) => {
    const items = [...this.state.items];
    if (selectedOption.value === items[index].status) return;

    items[index] = { ...items[index], status: selectedOption.value };
    const data_to_update = {status: selectedOption.value}
    if (selectedOption.value === SUCCESS){
      items[index].break_locations = [{ ...DEFAULT_BREAK_LOCATION, uuid: uuidv4() }];
      items[index].course_of_action = null;
      data_to_update.break_locations = null;
      data_to_update.course_of_action = null;
    }
    this.setState({ items });
    this.updateItemFieldChanged(items[index], data_to_update); 
  };

  /**
 * Handles the change of an item's course of action.
 *
 * @param {object} selectedOption - The selected option representing the new course of action.
 * @param {number} index - The index of the item in the items array.
 */
  handleCourseOfActionChange = (selectedOption, index) => {
    const items = [...this.state.items];
    items[index] = { ...items[index], course_of_action: selectedOption.value };
    this.setState({ items });
    this.updateItemFieldChanged(items[index], {course_of_action: selectedOption.value});
  };

  /**
 * Removes the UUID property from an item's break locations.
 *
 * @param {number} item_idx - The index of the item in the items array.
 * @returns {Array} Returns an array of break locations without the UUID property.
 */
  removeUuid = (item_idx) => {
    return this.state.items[item_idx].break_locations.map(item => {
      const { uuid, ...break_location } = item;
      return break_location
    });
  };

  /**
 * Handles the change of a process step for a break location.
 *
 * @param {object} selectedOption - The selected option representing the new process step.
 * @param {number} item_idx - The index of the item in the items array.
 * @param {number} location_idx - The index of the break location within the item.
 */
  handleProcessStepChange = (selectedOption, item_idx, location_idx) => {
    this.setState(prevState => {
      const items = [...prevState.items];
      items[item_idx].break_locations[location_idx] = {
        ...items[item_idx].break_locations[location_idx],
        process_step: selectedOption.value,
      };
      return { items };
    }, () => {
      const break_locations = this.removeUuid(item_idx);
      this.updateItemFieldChanged(
        this.state.items[item_idx], {break_locations: JSON.stringify(break_locations)}
      )
    })    
  };   
  
  /**
 * Handles the change of a location value for a break location.
 *
 * @param {object} selectedOption - The selected option representing the new location value.
 * @param {number} item_idx - The index of the item in the items array.
 * @param {number} location_idx - The index of the break location within the item.
 * @param {object} location - The current break location object.
 * @param {string} location_key - The key of the location property to be updated.
 */
  handleLocationChange = (selectedOption, item_idx, location_idx, location, location_key) => {
    this.setState(prevState => {
      const items = [...prevState.items];
      location = items[item_idx].break_locations[location_idx];
      location[location_key] = selectedOption.value;
      items[item_idx].break_locations[location_idx] = location;
      return { items };
    }, () => {
      const break_locations = this.removeUuid(item_idx);
      this.updateItemFieldChanged(
        this.state.items[item_idx], {break_locations: JSON.stringify(break_locations)}
      )
    })    
  };

  /**
 * Returns a formatted value object for a given value.
 *
 * @param {*} value - The value to be formatted.
 * @returns {object} Returns a formatted value object.
 */
  getFormattedValue = (value) => {
    return value ? { value, label: value } : undefined;
  }; 

  /**
 * Removes a break location from an item.
 *
 * @param {number} idx - The index of the item in the items array.
 * @param {number} location_idx - The index of the break location to be removed.
 */
  removeBreakLocation = (idx, location_idx) => {
    this.setState(prevState => {
      const { items } = prevState;
      const updatedItems = [...items];
      const updatedBreakLocations = updatedItems[idx].break_locations.filter((_, index) => index !== location_idx);
      updatedItems[idx] = {
        ...updatedItems[idx],
        break_locations: updatedBreakLocations
      };
      return { items: updatedItems };
    }, () => {
      const break_locations = this.removeUuid(idx);
      this.updateItemFieldChanged(
        this.state.items[idx], {break_locations: JSON.stringify(break_locations)}
      )
    })    
  };

  /**
 * Adds a new break location to an item.
 *
 * @param {number} idx - The index of the item in the items array.
 */
  addBreakLocation = (idx) => {
    this.setState(prevState => {
      const { items } = prevState;
      const updatedItems = [...items];
      updatedItems[idx] = {
        ...updatedItems[idx],
        break_locations: [...updatedItems[idx].break_locations, {uuid: uuidv4()}]
      };
      return { items: updatedItems };
    }, () => {
      const break_locations = this.removeUuid(idx);
      this.updateItemFieldChanged(
        this.state.items[idx], {break_locations: JSON.stringify(break_locations)}
      )
    })    
  };

  /**
 * Displays the break location selection for a wire item.
 *
 * @param {object} item - The wire item.
 * @param {number} idx - The index of the item in the items array.
 * @param {number} location_idx - The index of the break location within the item.
 * @param {object} location - The break location object.
 * @param {boolean} edit - Flag indicating whether the item is in edit mode.
 * @returns {JSX.Element} Returns the JSX element for displaying the break location selection.
 */
  displaySelectBreakLocation = (item, idx, location_idx, location, edit) => {
    const is_lower = item.ireq_details__ireq_parts__description.toLowerCase().includes('lower');
    if (!edit) {
      return <div className='location-container'>
          {`${location?.start_location}${location?.start_location_number} to ${location?.end_location}${location?.end_location_number}`}
      </div>
    }
    return <div className='location-container'>
      <div className='start-end-location'>
        <Select
          name="start-location"
          placeholder="-"
          value={this.getFormattedValue(location?.start_location)}
          options={LOCATION_OPTION[is_lower ? LOWER : UPPER]}
          onChange={(selectedOption) =>
            this.handleLocationChange(selectedOption, idx, location_idx, location, 'start_location')
          }
          className="custom-select"
          menuPortalTarget={document.body}
        />
        <Select
          name="start-location_number"
          placeholder="-"
          value={this.getFormattedValue(location?.start_location_number)}
          options={LOCATION_NUMBERS}
          onChange={(selectedOption) =>
            this.handleLocationChange(selectedOption, idx, location_idx, location, 'start_location_number')
          }
          className="custom-select"
          menuPortalTarget={document.body}
        />
      </div>
      <span className='bold-text'>to</span>
      <div className='start-end-location'>
        <Select
          name="end-location"
          placeholder="-"
          value={this.getFormattedValue(location?.end_location)}
          options={LOCATION_OPTION[is_lower ? LOWER : UPPER]}
          onChange={(selectedOption) =>
            this.handleLocationChange(selectedOption, idx, location_idx, location, 'end_location')
          }
          className="custom-select"
          menuPortalTarget={document.body}
        />
        <Select
          name="end-location_number"
          placeholder="-"
          value={this.getFormattedValue(location?.end_location_number)}
          options={LOCATION_NUMBERS}
          onChange={(selectedOption) =>
            this.handleLocationChange(selectedOption, idx, location_idx, location, 'end_location_number')
          }
          className="custom-select"
          menuPortalTarget={document.body}
        />
      </div>
    </div>
  };

  /**
 * Displays the wire items, including photos, course of action, break locations, and process steps.
 *
 * @param {object} item - The wire item object.
 * @param {number} idx - The index of the wire item.
 * @param {boolean} edit - Flag indicating whether the item is in edit mode.
 * @returns {JSX.Element} Returns the JSX element for displaying the wire items.
 */
  displayWireItens = (item, idx, edit, modal) => {
    const tableCellClass = modal ? 'table-cell-modal' : 'table-cell';
    const boldClass = modal ? 'bold-text-modal' : 'bold-text';
    const photos = this.props.ws_data.ir_files.filter(file => file.file_type==='photos' && file.ireq_wire_selection_id===item.id);
    return <>
      <div className="table-item-row">
        <div className={`${tableCellClass} ${boldClass}`}>
          {!edit && `${idx + 1}.`} {`${item.ireq_details__ireq_parts__description} (${item?.original_smartwire})`}
        </div>
        <div className={`${tableCellClass} second-col`}>
          {edit ?
            <Select
              name="fabrication_status"
              placeholder="Select..."
              value={this.getFormattedValue(item?.status)}
              options={FABRICATION_STATUSES}
              onChange={(selectedOption) =>
                this.handleStatusChange(selectedOption, idx)
              }
              classNamePrefix="custom-select"
              menuPortalTarget={document.body}
            /> :
            <span className={item?.status === SUCCESS ? 'success-green' : 'failure-red'}>{item?.status}</span>
            }
        </div>
      </div>
      {(item?.status === FAILURE) && (
        <>
          {photos.length > 0 || edit ? (
            <>
            <div className="table-row">
              <div className={`${tableCellClass} ${boldClass}`}>
                <div className="margin-left-40">Photos</div>
              </div>
              {edit && <div className={`${tableCellClass} second-col`}>
                <button
                  className='transparent-button lg fit'
                  onClick={() => {
                    this.onUploadClick(item);
                  }}
                >
                  Upload
                </button>
              </div>}
            </div>
            <div className="table-row">
              <div className="margin-left-40">
                <ThumbnailURLInterface
                  files={photos}
                  images={configLightBox(photos)}
                  theme="dark"
                  allow_remove={edit ? true : false}
                  onRemove={(image) => this.removeIRFile(image)}
                  key={this.state.refreshThumbnail}
                  fit
                  hide_occlusion={true}
                />
              </div>
            </div>
            <div className="line-division"/>
            </>
            ) : null}
          <div className="table-row">
            <div className={`${tableCellClass} ${boldClass}`}>
              <div className="margin-left-40">Course of Action</div>
            </div>
            <div className={`${tableCellClass} second-col`}>
              {edit ?
                <Select
                  name="course_of_action"
                  placeholder="Select action"
                  value={this.getFormattedValue(item?.course_of_action)}
                  options={item.original_smartwire.toLowerCase() === 'template' ? COURSE_OF_ACTION_ONLY_REDESIGN : COURSE_OF_ACTION}
                  onChange={(selectedOption) =>
                    this.handleCourseOfActionChange(selectedOption, idx)
                  }
                  classNamePrefix="custom-select"
                  menuPortalTarget={document.body}
                /> : item?.course_of_action}
            </div>
          </div>
          <div className="line-division"/>
          {item?.break_locations.map((location, location_idx) => (
            <React.Fragment key={location.uuid}>
              <div className="table-row">
                <div className={`${tableCellClass} ${boldClass}`}>
                  <div className="margin-left-40">Break Location</div>
                </div>
                <div className={`${tableCellClass} second-col`}>
                  {this.displaySelectBreakLocation(item, idx, location_idx, location, edit)}
                </div>
              </div>
              <div className='container-relative'>
                {location_idx > 0 && edit && (<span className="delete-location" onClick={() => this.removeBreakLocation(idx, location_idx)}>x</span>)}
              </div>
              <div className="table-row">
                <div className={`${tableCellClass} ${boldClass}`}>
                  <div className="margin-left-40">Process Step</div>
                </div>
                <div className={`${tableCellClass} second-col`}>
                  {edit ?
                    <Select
                      name="process-step"
                      placeholder="Select step wire failed"
                      value={this.getFormattedValue(location?.process_step)}
                      options={PROCESS_STEP}
                      onChange={(selectedOption) =>
                        this.handleProcessStepChange(selectedOption, idx, location_idx)
                      }
                      classNamePrefix="custom-select"
                      menuPortalTarget={document.body}
                    /> : location?.process_step}
                </div>
              </div>
              <div className="line-division"/>
            </React.Fragment>
          ))}
          {edit &&
          <div className="table-row" onClick={() => this.addBreakLocation(idx)}>
              <div className="margin-left-40 add-location-link">
                Add another break locaiton
                <i class="fa fa-plus-circle" aria-hidden="true"/>
              </div>
          </div>}
        </>
      )}
    </>
  };

  /**
 * Displays the wire items in a table format.
 *
 * @param {Array} items - The array of wire items.
 * @param {boolean} edit - Flag indicating whether the items are in edit mode.
 * @param {boolean} modal - Flag indicating whether the items are displayed in a modal.
 * @returns {JSX.Element} Returns the JSX element for displaying the wire items.
 */
  displayWires = (items, edit=true, modal=false) => {
    const tableCellClass = modal ? 'table-cell-modal' : 'table-cell';
    return (
      <div className={edit ? "table-items-edit" : "table-items-modal"}>
        {edit && <div className="table-header">
          <div className={tableCellClass}>Wire</div>
          <div className={`${tableCellClass} second-col`}>Status</div>
        </div>}
        {items.map((item, idx) => {
          return <React.Fragment key={idx}>
            {this.displayWireItens(item, idx, edit, modal)}
          </React.Fragment>
        })}
      </div>
    );
  };

  /**
 * Returns the initiated date for wire fabrication review.
 *
 * @returns {string|null} Returns the initiated date if available, otherwise null.
 */
  getWireFabractionInitiatedDate() {
    return this.props?.ws_data?.ws?.find(item => item.status === WS_WIRE_FABRICATION_STATUS).created_date;
  };

  /**
 * Returns the resolved date for wire fabrication review.
 *
 * @returns {string|null} Returns the resolved date if available, otherwise null.
 */
  getWireFabractionResolvedDate() {
    return this.props?.ws_data?.ws?.find(item => item.current_ind === true).modified_date;
  };

  /**
 * Displays the resolved message for wire fabrication review.
 *
 * @returns {JSX.Element} Returns the JSX element for displaying the resolved message.
 */
  displayResolvedMessage() {
    return (
      <div className="resolve-message-box">
        <span className="bold-text">Wire Fabrication Review Initiated </span>
        {convertDate(this.getWireFabractionInitiatedDate())}
        <span className="bold-text"> Resolved </span>
        {convertDate(this.getWireFabractionResolvedDate())}
      </div>
    );
  };
   
  /**
 * Generates an action button element.
 *
 * @param {string} action_modal - The action modal name.
 * @param {string} label - The label for the button.
 * @param {boolean} enabled - Flag indicating whether the button is enabled.
 * @returns {JSX.Element} Returns the JSX element for the action button.
 */
  getActionButton(action_modal, label, enabled=true) {
    return (
      <button type="button" className="btn btn-light" onClick={() => this.setState({action_modal})} disabled={!enabled}>
        {label}
      </button>
    );
  };

  /**
 * Checks if all wire items are either successful or require recut.
 *
 * @returns {boolean} Returns true if all wire items are either successful or require recut, otherwise false.
 */
  isOnlyRecut = () => {
    const { items } = this.state;
    return items.every(item => (item.status === SUCCESS) || (item.status === FAILURE && item.course_of_action === 'Recut'))
  };

  /**
 * Checks if the "Complete" button should be enabled.
 *
 * @returns {boolean} Returns true if the "Complete" button should be enabled, otherwise false.
 */
  isCompleteButtonEnabled = () => {
    const { items } = this.state;
    const hasFailure = items.some(item => item.status === FAILURE); 
    const isComplete = items.filter(item => item.status === FAILURE).every(item => (
      item.course_of_action && 
      item.break_locations.every(location => (
        location.process_step &&
        location.start_location &&
        location.start_location_number &&
        location.end_location &&
        location.end_location_number
      ))
    ));
  
    return hasFailure && isComplete;
  };

  /**
 * Displays the action buttons based on the current status.
 *
 * @param {boolean} permitted - Flag indicating whether the user is permitted to perform the actions.
 * @returns {JSX.Element|null} Returns the JSX element for displaying the action buttons, or null if no buttons are available.
 */
  displayButtons(permitted) {
    const buttons = {};
    const action = this.isOnlyRecut() ? "complete_wire_fabrication_recut" : "complete_wire_fabrication";
    buttons[WS_WIRE_FABRICATION_STATUS] =
      <div>
        <div className="bpp-10-padding" />
        <div className="text-center">
          {this.getActionButton(action, "Complete", this.isCompleteButtonEnabled() && permitted)}
          {this.getActionButton("cancel_wire_fabrication", "Cancel", permitted)}
        </div>
      </div>
    ;
    buttons[WS_WIRE_FABRICATION_REINITIATED_STATUS] = buttons[WS_WIRE_FABRICATION_STATUS];
    buttons[WS_COMPLETE_FABRICATION_STATUS] =
      <div>
        <div className="bpp-10-padding" />
        <div className="text-center">
          {this.getActionButton("resolve_wire_fabrication", "Resolved", this.isCompleteButtonEnabled() && permitted)}
          {this.getActionButton("reinitiate_wire_fabrication", "Reinitiate", permitted)}
        </div>
      </div>
    ;

    if (this.props.status in buttons) {
      return buttons[this.props.status]
    };
  };
  
  /**
 * Performs an HTTP request to update the status action.
 *
 * @param {string} action - The action to be performed.
 */
  fetchUpdateStatusAction(action) {
    setTokenHeader()
    this.setState({ modal_in_progress: true });
    const that = this;
    Axios.post(`/apiv3/ireqautomationstatus/${this.props.ireq_id}/ws/${action}`)
      .then(function (res) {
        that.setState({
          action_modal: '',
          modal_in_progress: false,
        });
        that.props.refreshWSWorkflow()
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        if (err && err.response && err.response.status === 409) {
          that.setState({
            action_modal: '',
            modal_in_progress: false,
          });
        }
      });
  }

  /**
 * Handles the confirmation of the modal action.
 */
  onModalConfirm() {
    this.fetchUpdateStatusAction(this.state.action_modal);
  };

  /**
 * Displays the modal action based on the current action_modal state.
 *
 * @returns {JSX.Element|null} Returns the JSX element for displaying the modal action, or null if no modal action is available.
 */
  displayModalAction = () => {
    const { action_modal } = this.state;
    const ireq_id = removeIreqIdInitialNumber(this.props.ireq_id)
    const modals = {
      complete_wire_fabrication: {
        preset: "decision-wire-selection",
        header_text: `Wire Fabrication Review - ${ireq_id}`,
        message_text: 
        <>
          <p>Please confirm the failed smartwires below</p>
          {this.displayWires(this.state.items.filter(item => item?.status === FAILURE), false, true)}
        </>,
        confirm_btn_text: "Confirm",
        close_btn_text: "Cancel",
        in_progress_text: 'Confirming...',
      },
      cancel_wire_fabrication: {
        preset: "decision-wire-selection",
        header_text: `Wire Fabrication Review - ${ireq_id}`,
        message_text: 
        <>
          <p>Are you sure you want to cancel the following wire fabrication review?</p>
        </>,
        confirm_btn_text: "Confirm",
        close_btn_text: "Cancel",
        in_progress_text: 'Confirming...',
      },
      reinitiate_wire_fabrication: {
        preset: "decision-wire-selection",
        header_text: `Wire Fabrication Review - ${ireq_id}`,
        message_text: 
        <>
          <p>Would you like to reinitiate wire fabrication review?</p>
        </>,
        confirm_btn_text: "Reinitiate",
        close_btn_text: "Cancel",
        in_progress_text: 'Reinitiating...',
      },
      resolve_wire_fabrication: {
        preset: "decision-wire-selection",
        header_text: `Wire Fabrication Review - ${ireq_id}`,
        message_text: 
        <>
          <p>Are you sure you want to resolve the following wire fabrication?</p>
        </>,
        confirm_btn_text: "Resolve",
        close_btn_text: "Cancel",
        in_progress_text: 'Resolving...',
      },
    };
    modals['complete_wire_fabrication_recut'] = modals['complete_wire_fabrication'];

    if (action_modal in modals) {
      return (
        <Modal
          onCloseButtonClick={() => this.setState({action_modal: ""})}
          theme="bpp"
          modal_size_class="modal"
          {...modals[action_modal]}
          in_progress={this.state.modal_in_progress}
          onConfirmButtonClick={this.onModalConfirm}
        />
      );
    }
    return null;
  };

  /**
 * Fetches and updates the IR file with the provided upload data.
 *
 * @param {object} item - The wire item object.
 * @param {array} upload_data - The array of upload data.
 */
  fetchUpdateIRFile = (item, upload_data) => {
    upload_data[0]['ireq_wire_selection_id'] = item.id;
    upload_data[0]['manual_process'] = true;
    upload_data[0]['file_type'] = 'photos';

    const ireq_id = this.props.ireq_id;
    const action = 'wire_fabrication_upload_photo'
    const that = this;
    Axios.put(`/apiv3/ireqautomationstatus/${ireq_id}/ws/${action}`, upload_data)
      .then(function (res) {
        that.setState({ upload_modal: null });
        that.props.refreshWSWorkflow()
      })
      .catch(function (err) {
        that.setState({ upload_modal: null });
        handleHttpRequestError(err, that);
      });
  };

  /**
 * Fetches and updates the IR file with the provided upload data.
 *
 * @param {object} item - The wire item object.
 * @param {array} upload_data - The array of upload data.
 */
  removeIRFile = (image) => {
    const ireq_id = this.props.ireq_id;
    const action = 'wire_fabrication_remove_photo'
    const that = this;
    Axios.put(`/apiv3/ireqautomationstatus/${ireq_id}/ws/${action}`, image)
      .then(function () {
        that.props.refreshWSWorkflow()
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
 * Handles the click event for uploading photos.
 *
 * @param {object} item - The wire item object.
 */
  onUploadClick = (item) => {
    const ireq_id = this.props.ireq_id;
    const config = {
      header_text: `Photo Upload - ${removeIreqIdInitialNumber(ireq_id)}`,
      ireq_id: ireq_id,
      preset: "upload",
      case_id: 'this.state.selected_case_id',
      folder: "photos",
      type: "image",
      onUpload: (data) => {this.fetchUpdateIRFile(item, data)},
      upload_content: [],
      theme: "bpp",
    };
    this.setState({
      upload_modal: config,
    });
  };

  /**
 * Displays the upload modal for uploading photos.
 *
 * @returns {JSX.Element} Returns the JSX element for the upload modal.
 */
  displayUploadModal = () => {
    return (
      <Modal
        onCloseButtonClick={() => {
          this.setState({ upload_modal: null });
        }}
        {...this.state.upload_modal}
      />
    )
  };

  /**
 * Displays the modal for displaying information or user interaction.
 *
 * @returns {JSX.Element} Returns the JSX element for the modal.
 */
  displayModal = () => {
    return (
      <Modal
      onCloseButtonClick={() => {
          this.setState({ modal: null });
        }}
        {...this.state.modal}
      />
    )
  };

  /**
 * Retrieves the wire fabrication status logs.
 *
 * @returns {array} Returns an array of wire fabrication status logs.
 */
  getWireFabricationStatusLogs = () => {
    // find the index of first Wire Fabrication to show all the logs starting in that status
    // then filter only status that will show logs
    const logs = [...this.props?.ws_data?.ws].reverse();
    const found_idx = logs.findIndex(item => item.status === WS_WIRE_FABRICATION_STATUS);
    if (found_idx === -1) return;    
    return logs.slice(found_idx).filter(item => WIRE_FABRICATION_LOG_STATUSES.hasOwnProperty(item.status));
  };

  /**
 * Displays the wire fabrication status logs.
 */
  displayLogs = () => {
    console.log(this.props.ws_data.ws_details);
    const items = this.props.ws_data.ws_details.filter(item => item.group === 'smartwire').map(item => {
      return this.updateItem(item)
    });
    const ireq_id = removeIreqIdInitialNumber(this.props.ireq_id);
    const status_log = this.getWireFabricationStatusLogs();
    const body = status_log.map(status => {
      return (
        <div className='align-left'>
          <span className='bold-text'>{convertDateWithFormat(status.modified_date, 'MM/DD/YY hh:mm A')}</span>
          {' '}
          {WIRE_FABRICATION_LOG_STATUSES[status.status]}
          {status.rework && ` (Rework ${status.rework})`}
          {[WS_REDESIGN_FABRICATION_STATUS, WS_COMPLETE_FABRICATION_STATUS].includes(status.status) &&
            <div className='log-wire-container'>
               {this.displayWires(items.filter(item => item.automation_status_id === status.id), false, true)}
            </div>
          }
        </div>
      );
    });
    
    const config = {
      header_text: `Wire Fabrication Review Log - ${ireq_id}`,
      message_text: 
      <>
        {body}
      </>,
      close_btn_text: "Close",
      theme: "bpp",
      modal_size_class: "modal-lg",
    }
    this.setState({modal: config});
  };

  /**
 * Checks if the user has role permission.
 *
 * @param {object} user_roles_and_permissions - The user roles and permissions object.
 * @returns {boolean} Returns true if the user has role permission, otherwise false.
 */
  hasRolePermission = (user_roles_and_permissions) => {
    return (_.intersection(user_roles_and_permissions.user_roles, PERMISISON_ROLES).length > 0);
  }

  render() {
    return (
      <UserPermissionsContext.Consumer>
      {(user_roles_and_permissions) => {
        const permitted = this.hasRolePermission(user_roles_and_permissions);
        return <>
          <div className="bold-text business-dark-heading log-header">
            Wire Fabrication Review
            <i className="fa fa-file-text document-icon" aria-hidden="true" onClick={this.displayLogs}/>
          </div>
          <div className="business-dark-theme">
          <div className="bpp-cozy-text-headlines">
              {(this.props.status === WS_WIRE_FABRICATION_RESOLVED_STATUS) ? 
                this.displayResolvedMessage() :
                (<>
                  {this.displayWires(this.state.items, EDIT_STATUSES.includes(this.props.status) && permitted)}
                  {this.displayButtons(permitted)}
                </>)
              }
            </div>
            {this.state.action_modal && this.displayModalAction()}
            {/* {this.displayFileUploadError()} */}
            {this.state.upload_modal && this.displayUploadModal()}  
            {this.state.modal && this.displayModal()}
          </div>
          {this.state.refresh && <SessionExpire />}
        </>
      }}
      </UserPermissionsContext.Consumer>
    );
  };  
};

export default (FabricationWorkflow);
