import {
  WS_COMPLETE_STATUS,
  WS_REDESIGN_FABRICATION_STATUS,
  WS_WIRE_FABRICATION_STATUS,
  WS_WIRE_FABRICATION_RESOLVED_STATUS,
  WS_WIRE_FABRICATION_REINITIATED_STATUS,
  WS_COMPLETE_FABRICATION_STATUS,
} from '../constants';

export const SUCCESS = "Success";
export const FAILURE = "Failure";

export const FABRICATION_STATUSES = [
  {value: SUCCESS, label: SUCCESS,},
  {value: FAILURE, label: FAILURE,},
];

export const COURSE_OF_ACTION = [
  {value: "Redesign", label: "Redesign",},
  {value: "Recut", label: "Recut",},
];

export const COURSE_OF_ACTION_ONLY_REDESIGN = [
  {value: "Redesign", label: "Redesign",},
];

export const PROCESS_STEP = [
  {value: "During Heat Treatment", label: "During Heat Treatment",},
  {value: "During Pickling", label: "During Pickling",},
  {value: "During Loading", label: "During Loading",},
  {value: "During Unloading", label: "During Unloading",},
];

export const LOWER = 'LOWER';
export const UPPER = 'UPPER';

export const LOCATION_OPTION = {
  [LOWER]: [
    {value: "LR", label: "LR",},
    {value: "LL", label: "LL",}
  ],
  [UPPER]: [
    {value: "UR", label: "UR",},
    {value: "UL", label: "UL",},
  ]
};

export const LOCATION_NUMBERS = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" }
];

export const DEFAULT_BREAK_LOCATION = {
  start_location: null,
  start_location_number: null,
  end_location: null,
  end_location_number: null,
  process_step: null
}

export const WIRE_FABRICATION_LOG_STATUSES = {
  [WS_WIRE_FABRICATION_STATUS]: 'Wire Fabrication Review Initiated',
  [WS_COMPLETE_STATUS]: 'Wire Fabrication Review Cancelled',
  [WS_REDESIGN_FABRICATION_STATUS]: 'Wire Fabrication Review Completed', 
  [WS_WIRE_FABRICATION_REINITIATED_STATUS]: 'Wire Fabrication Review Reinitiated',
  [WS_COMPLETE_FABRICATION_STATUS]: 'Resolve Wire Fabrication Failure Process Started', 
  [WS_WIRE_FABRICATION_RESOLVED_STATUS]: 'Resolve Wire Fabrication Failure Resolved', 
}

export const EDIT_STATUSES = [WS_WIRE_FABRICATION_STATUS, WS_WIRE_FABRICATION_REINITIATED_STATUS]
export const PERMISISON_ROLES = ['PRPL', 'Software']
