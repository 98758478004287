import _ from 'lodash';
import { firstLetterCapital, orderTeeth, removeCaseIdInitialNumber, removeIreqIdInitialNumber } from './functions';
import Moment from 'moment';
import { CaseHistoryStatus, CaseStatus } from './case/case_details.constants';

let DropboxInstance = require('dropbox').Dropbox;

const applyCSSToID = (id, css) => {
  const element = document.querySelector(id);

  if (element) {
    element.classList.add(css);
  }
};

const getFilterDefinition = () => {
  return {
    draft: 'Draft Submission',
    new: 'Upload New Records',
    setup: 'Review Smile Design',
    hold: 'Hold',
    ship: 'Shipped',
    cancel: 'Cancelled',
    clarification: 'Pending Doctor Clarification',
    review: 'Reviewing Request',
    prepare: 'Fabricating Items',
  };
};

const getLastCaseStatusFromStatusesList = (caseStatuses, ignoreHoldTypeStatuses = true) => {
  if (!ignoreHoldTypeStatuses) {
    const lastStatus = caseStatuses.length > 0 ? caseStatuses.pop().case_status : '';
    return lastStatus;
  }

  const statusesToIgnore = [
    CaseStatus.Cancelled,
    CaseStatus.Uncancelled,
    CaseStatus.Hold,
    CaseStatus.Unhold,

    CaseStatus.StatusCancel,
    CaseStatus.StatusUncancel,
    CaseStatus.StatusHold,
    CaseStatus.StatusUnhold,
    CaseStatus.DoctorProvideClarification,
    CaseStatus.DoctorClarificationResolved,
    CaseStatus.FolderGeneration,
  ];

  const filteredStatuses = caseStatuses.filter((status) => !statusesToIgnore.includes(status.case_status));
  const lastStatus = filteredStatuses.length > 0 ? filteredStatuses.pop().case_status : '';
  return lastStatus;
};

const getCaseLastStatus = (translate, case_details, selected_case_id) => {
  let last_status = '';
  const selected_case = getCaseByCaseId(case_details, selected_case_id);

  const case_status = getLastCaseStatusFromStatusesList(selected_case.case_statuses);

  if (
    selected_case.status_code === 'Cancelled' ||
    selected_case.status_code === 'Hold' ||
    selected_case.status_code === 'STATUS_HOLD' ||
    selected_case.status_code === 'STATUS_CANCEL' ||
    selected_case.status_code === 'Doctor Provide Clarification'
  ) {
    last_status = translate ? translateLastStatus(case_status) : case_status;
  }

  return last_status;
};

const getLastStatusBySelectedCase = (selected_case) => {
  let last_status = '';

  let case_statuses = _.cloneDeep(selected_case.case_statuses).filter((statuses) => {
    return (
      statuses.case_status !== 'Uncancelled' &&
      statuses.case_status !== 'Cancelled' &&
      statuses.case_status !== 'STATUS_CANCEL' &&
      statuses.case_status !== 'STATUS_UNCANCEL' &&
      statuses.case_status !== 'Hold' &&
      statuses.case_status !== 'Unhold' &&
      statuses.case_status !== 'STATUS_HOLD' &&
      statuses.case_status !== 'STATUS_UNHOLD' &&
      statuses.case_status !== 'Folder Generation Process' &&
      statuses.case_status !== 'Doctor Provide Clarification' &&
      statuses.case_status !== 'Doctor Clarification Resolved'
    );
  });

  const case_status = case_statuses.length > 0 ? case_statuses[case_statuses.length - 1].case_status : '';

  if (
    selected_case.status_code === 'Cancelled' ||
    selected_case.status_code === 'Hold' ||
    selected_case.status_code === 'STATUS_HOLD' ||
    selected_case.status_code === 'STATUS_CANCEL' ||
    selected_case.status_code === 'Doctor Provide Clarification'
  ) {
    last_status = translateLastStatus(case_status);
  }

  return last_status;
};

const getLastStatusDefinition = () => {
  return {
    'INBRACE Checking Files': 'STATUS_INBRACE_CHECK',
    'INBRACE Preparing Setup': 'STATUS_INBRACE_SETUP',
  };
};

const getIPPLastStatusDefinition = () => {
  return {
    'INBRACE Checking Files': 'STATUS_INBRACE_CHECK',
  };
};

/**
 * Defines user-friendly statuses for status codes
 * @function
 * @return {Object} Status definitions
 */
const getStatusDefinition = () => {
  // Defines the description of the status code
  return {
    STATUS_DRAFT_SUB: 'Draft Submission',
    STATUS_DRAFT_DE: 'Draft Digital Enhancement',
    STATUS_NEW_SUB: 'Case Submitted',
    STATUS_NEW_DE: 'Digital Enhancement Submitted',
    STATUS_DOCTOR_APPROVAL: 'Doctor Approve or Revise Smile Design',
    STATUS_DOCTOR_UPLOAD: 'Doctor Upload New Records',
    STATUS_HOLD: 'Hold',
    STATUS_INBRACE_CHECK: 'InBrace Verifying Records',
    STATUS_INBRACE_CHECK_APPROVE: 'InBrace Approved Files',
    STATUS_INBRACE_SETUP: 'InBrace Preparing Smile Design',
    STATUS_INBRACE_SETUP_APPROVE: 'InBrace Approved Smile Design',
    STATUS_MANUFACTURE: 'InBrace Fabricating Case',
    STATUS_SHIP: 'Case Shipped',
    STATUS_CANCEL: 'Cancelled',
    'Draft Submission': 'Draft Submission',
    'Draft Digital Enhancement': 'Draft Digital Enhancement',
    'Case Submitted': 'Case Submitted',
    'Digital Enhancement Submitted': 'Digital Enhancement Submitted',
    'Doctor Approve or Revise Setup': 'Review Smile Design',
    'Doctor Upload New Files': 'Doctor Upload New Records',
    Hold: 'Hold',
    'INBRACE Checking Files': 'InBrace Verifying Records',
    'INBRACE Approved Files': 'InBrace Approved Files',
    'INBRACE Preparing Setup': 'InBrace Preparing Smile Design',
    'INBRACE Approved Setup': 'InBrace Approved Smile Design',
    'INBRACE Manufacturing Case': 'InBrace Fabricating Case',
    'Case Shipped': 'Case Shipped',
    'Item Request On Hold': 'Hold',
    'Item Request Cancelled': 'Cancelled',
    Cancelled: 'Cancelled',
    'Folder Generation Process': 'Folder Generation Process',
    'Folder Generation Failed': 'Folder Generation Failed',
    'Folder Generation Success': 'Folder Generation Success',
    'Segmentation Process': 'Segmentation Process',
    'Setup Ready for Upload': 'Smile Design Ready for Upload',
    'Setup Conversion Process': 'Smile Design Conversion Process',
    'Setup Conversion Failed': 'Smile Design Conversion Failed',
    'Setup Review': 'Smile Design Review',
    'Revise Setup': 'Revise Smile Design',
    'CAD Process': 'CAD Process',
    'Quality Control Review': 'Quality Control Review',
    'Doctor Provide Clarification': 'Doctor Provide Clarification',
  };
};

/**
 * Defines IPP user-friendly statuses
 * @function
 * @return {Object} IPP status definitions
 */
const getIPPStatusDefinition = () => {
  const statusDefinitions = getStatusDefinition();
  statusDefinitions['STATUS_NEW_SUB'] = 'InBrace Verifying Records';
  statusDefinitions['STATUS_NEW_DE'] = 'InBrace Verifying Records';
  statusDefinitions['Folder Generation Process'] = 'InBrace Verifying Records';
  statusDefinitions['Folder Generation Failed'] = 'InBrace Verifying Records';
  statusDefinitions['Folder Generation Success'] = 'InBrace Verifying Records';
  statusDefinitions['Segmentation Process'] = 'InBrace Verifying Records';
  statusDefinitions['Awaiting Folder Generation Process'] = 'InBrace Verifying Records';
  statusDefinitions['Doctor Provide Clarification'] = 'Pending Doctor Clarification';

  // Setup
  statusDefinitions['INBRACE Preparing Setup'] = 'InBrace Preparing Setup';
  statusDefinitions['Setup Review'] = 'InBrace Preparing Setup';
  statusDefinitions['Revise Setup'] = 'InBrace Preparing Setup';
  statusDefinitions['Setup Ready for Upload'] = 'InBrace Preparing Setup';
  statusDefinitions['Setup Conversion Process'] = 'InBrace Preparing Setup';
  statusDefinitions['Setup Conversion Failed'] = 'InBrace Preparing Setup';
  statusDefinitions['Setup Ready for Release'] = 'InBrace Preparing Setup';

  // Setup New
  statusDefinitions['Smile Design Review'] = 'Preparing Smile Design';
  statusDefinitions['Revise Smile Design'] = 'Preparing Smile Design';
  statusDefinitions['Smile Design Ready for Upload'] = 'Preparing Smile Design';
  statusDefinitions['Smile Design Conversion Process'] = 'Preparing Smile Design';
  statusDefinitions['Smile Design Conversion Failed'] = 'Preparing Smile Design';
  statusDefinitions['Smile Design Ready for Release'] = 'Preparing Smile Design';
  statusDefinitions['InBrace Preparing Smile Design'] = 'Preparing Smile Design';
  statusDefinitions['InBrace Fabricating Case'] = 'Fabricating Case';

  // Manufacturing
  statusDefinitions['CAD Process'] = 'InBrace Manufacturing Case';
  statusDefinitions['Quality Control Review'] = 'InBrace Manufacturing Case';
  statusDefinitions['INBRACE Manufacturing Case'] = 'InBrace Manufacturing Case';

  return statusDefinitions;
};

const getStatusDefinitionIncomplete = () => {
  // Defines the description of the status code when it is incomplete

  return {
    STATUS_NEW_SUB: CaseHistoryStatus.StartedRecordVerification,
    STATUS_NEW_DE: CaseHistoryStatus.StartedRecordVerification,
    'Folder Generation Process': CaseHistoryStatus.StartedRecordVerification,
    'Folder Generation Failed': CaseHistoryStatus.StartedRecordVerification,
    'Folder Generation Success': CaseHistoryStatus.StartedRecordVerification,
    'Segmentation Process': CaseHistoryStatus.StartedRecordVerification,
    'Awaiting Folder Generation Process': CaseHistoryStatus.StartedRecordVerification,
    STATUS_INBRACE_CHECK: CaseHistoryStatus.StartedRecordVerification,
    'INBRACE Checking Files': CaseHistoryStatus.StartedRecordVerification,
    STATUS_DOCTOR_APPROVAL: CaseHistoryStatus.ProviderRequestedRevision,
    STATUS_DOCTOR_UPLOAD: 'Upload New Records',
    STATUS_INBRACE_SETUP: 'InBrace Uploaded Setup',
  };
};

const getStatusDefinitionComplete = () => {
  // Defines the description of the status code when it is complete
  return {
    // STATUS_DRAFT_SUB: 'Draft Submission',
    // STATUS_DRAFT_DE: 'Draft Digital Enhancement',
    // STATUS_NEW_SUB: 'Case Submitted',
    // STATUS_NEW_DE: 'Digital Enhancement Submitted',
    STATUS_DOCTOR_APPROVAL: 'Doctor Approved Setup',
    STATUS_DOCTOR_UPLOAD: 'Doctor Uploaded New Records',
    // STATUS_HOLD: 'Hold',
    STATUS_INBRACE_CHECK: CaseHistoryStatus.CompletedRecordVerification,
    // STATUS_INBRACE_CHECK_APPROVE: 'INBRACE Approved Files',
    STATUS_INBRACE_SETUP: 'INBRACE Released Setup',
    // STATUS_INBRACE_SETUP_APPROVE: 'INBRACE Approved Setup',
    // STATUS_MANUFACTURE: 'INBRACE Manufacturing Case',
    // STATUS_SHIP: 'Case Shipped',
    // STATUS_CANCEL: 'Cancelled'
  };
};

const getRoleDefinitionComplete = () => {
  return {
    Doctor: 'Doctor',
    Business: 'INBRACE BPP User',
    'Business Manager': 'INBRACE BPP User / Manager',
    'Account Manager': 'INBRACE BPP User / Account Manager',
    Admin: 'INBRACE BPP',
  };
};

/**
 * Defines statuses as true if doctor has something to do or hold
 * @function
 * @return {Object} Status alert definitions
 */
const getStatusAlertDefinition = () => {
  return {
    'Draft Submission': true,
    STATUS_DRAFT_SUB: true,
    'Draft Digital Enhancement': true,
    STATUS_DRAFT_DE: true,
    STATUS_DOCTOR_APPROVAL: true,
    STATUS_NEW_SUB: false,
    'Doctor Upload New Files': true,
    STATUS_DOCTOR_UPLOAD: true,
    STATUS_HOLD: true,
    STATUS_INBRACE_CHECK: false,
    STATUS_INBRACE_SETUP: false,
    STATUS_MANUFACTURE: false,
    STATUS_SHIP: false,
    STATUS_CANCEL: false,
    'Doctor Approve or Revise Setup': true,
    'Doctor Provide Clarification': true,
    Hold: true,
  };
};

const getStatusPriorityDefinition = () => {
  //////////////////////////////////////////////////////////////////////////////
  //   Old Priority (02/20/19)
  //
  //////////////////////////////////////////////////////////////////////////////
  // return {
  //   STATUS_DRAFT_SUB: 1,
  //   STATUS_DRAFT_DE: 1,
  //   STATUS_DOCTOR_APPROVAL: 2,
  //   STATUS_NEW_SUB: 3,
  //   STATUS_DOCTOR_UPLOAD: 4,
  //   STATUS_HOLD: 5,
  //   STATUS_INBRACE_CHECK: 6,
  //   STATUS_INBRACE_SETUP: 6,
  //   STATUS_MANUFACTURE: 6,
  //   STATUS_SHIP: 7,
  //   STATUS_CANCEL: 8
  // };

  return {
    STATUS_DRAFT_SUB: 1,
    STATUS_DRAFT_DE: 2,
    STATUS_DOCTOR_APPROVAL: 4,
    STATUS_NEW_SUB: 1,
    STATUS_DOCTOR_UPLOAD: 3,
    STATUS_HOLD: 5,
    STATUS_INBRACE_CHECK: 6,
    STATUS_INBRACE_SETUP: 7,
    STATUS_MANUFACTURE: 8,
    STATUS_SHIP: 9,
    STATUS_CANCEL: 10,
  };
};

const getSmileStatusPriorityDefinition = () => {
  return {
    Cancelled: 1,
    'Case Submitted': 2,
    'Doctor Upload New Scans': 3,
    'InBrace Verifying Scans': 4,
    'Prospect Lost': 5,
    'Resume Case Submission': 6,
    'Smile Simulation Conversion Failed': 7,
    'Smile Simulation Conversion Process': 8,
    'Smile Simulation Ready for Upload': 9,
    'Smile Simulation Ready for Release': 10,
    'Smile Simulation Released': 11,
  };
};

const getIrStatusPriorityDefinition = () => {
  return {
    'Item Request On Hold': 1,
    'Item Request Submitted': 2,
    'INBRACE Reviewing Request': 3,
    'Item Request Updated': 4,
    'Item Request Approved': 5,
    'INBRACE Manufacturing Item Request': 6,
    'Item Request Shipped': 7,
    'Item Request Cancelled': 8,
  };
};

const POSSIBLE_CANCEL_OR_HOLD_NEXT_STATUSES = [
  'STATUS_NEW_SUB',
  'Awaiting Folder Generation Process',
  'Folder Generation Process',
  'Folder Generation Failed',
  'Folder Generation Success',
  'STATUS_INBRACE_CHECK',
  'Segmentation Process',
  'STATUS_INBRACE_SETUP',
  'INBRACE Preparing Setup',
  'Setup Review',
  'Revise Setup',
  'Setup Ready for Upload',
  'Setup Conversion Failed',
  'Setup Conversion Process',
  'Setup Ready for Release',
  'Provider Edit Review',
  'Final Design Upload',
  'Final Design in Conversion',
  'Final Design Conversion Failed',
  'Final Design Ready for Release',
  'CAD Process',
  'Stage I',
  'Quality Control Stage I',
  'Stage II',
  'Stage III',
  'Quality Control Review',
];

const getBusinessRoleList = () => {
  return ['Business', 'Admin', 'Business Manager', 'Account Manager', 'Administrator'];
};

const getDsoRoleList = () => {
  return ['dso'];
};

const getBusinessRoleListLowerCase = () => {
  const old_role = getBusinessRoleList();
  const new_role = [];

  for (let i = 0; i < old_role.length; i++) {
    new_role.push(old_role[i].toLowerCase());
  }

  return new_role;
};

const getCaseByCaseId = (case_details, case_id) => {
  let cases = [];

  for (let i = 0; i < case_details.cases.length; i++) {
    if (case_details.cases[i].case_id === case_id) {
      cases = case_details.cases[i];
      break;
    }
  }

  return cases;
};

const getLastAssignedTo = (current_case, current_case_id) => {
  let last_assigned_to = '';

  let case_last_status = getCaseLastStatus(false, current_case, current_case_id);

  let case_statuses = _.cloneDeep(
    current_case.cases.filter(function (e) {
      return e.case_id === current_case_id;
    })[0].case_statuses
  )
    .sort((current_case_status, next_case_status) => parseInt(next_case_status.id) - parseInt(current_case_status.id))
    .filter((case_status) => {
      return case_status.case_status === case_last_status;
    });

  if (case_statuses.length > 0 && case_statuses[0]['assignment__first_name'] && case_statuses[0]['assignment__last_name']) {
    last_assigned_to = case_statuses[0]['assignment__first_name'] + ' ' + case_statuses[0]['assignment__last_name'];
  }

  return last_assigned_to === '' ? 'Unassigned' : last_assigned_to;
};

const getLastCADAssignTo = (current_case, current_case_id) => {
  let cad_assigned_to = 'Unassigned';
  const quality_status = 'Quality Control Review';
  const case_statuses = current_case.cases.filter(function (c) {
    return c.case_id === current_case_id;
  })[0].case_statuses;

  const cad_status = case_statuses.filter(function (case_status) {
    return case_status.case_status === quality_status;
  });

  if (cad_status && cad_status.length > 0) {
    cad_assigned_to = cad_status[cad_status.length - 1].assignment__first_name + ' ' + cad_status[cad_status.length - 1].assignment__last_name;
  }

  return cad_assigned_to;
};

const isCancelOrHoldNextStatus = (last_status, current_status) => {
  let result = false;

  if (last_status !== null) {
    for (let i = 0; i < POSSIBLE_CANCEL_OR_HOLD_NEXT_STATUSES.length; i++) {
      if (last_status && last_status !== current_status && POSSIBLE_CANCEL_OR_HOLD_NEXT_STATUSES[i] === last_status) {
        result = true;
        break;
      } else {
        result = false;
      }
    }
  }
  return result;
};

const isKnowledgeBaseRoute = () => {
  let href = window.location.href;
  let knowledgebase_ref = href.match('knowledgebase');
  return knowledgebase_ref;
};

const translateRole = (role) => {
  const role_definitions = getRoleDefinitionComplete();
  return role_definitions[role];
};

const translateCaseStatus = (cases, mode) => {
  return translateStatus(cases.status_code, mode);
};

const translateFilterStatus = (filter) => {
  return translateFilter(filter);
};

const translateFilter = (filter) => {
  const filter_definition = getFilterDefinition();
  return filter_definition[filter] ? filter_definition[filter] : filter;
};

const translateStatus = (status_code, mode) => {
  mode = mode || 'bpp';
  const status_definition = mode === 'bpp' ? getStatusDefinition() : getIPPStatusDefinition();
  return status_definition[status_code] ? status_definition[status_code] : status_code;
};

const truncateTextByLimit = (text, limit) => {
  let new_text = text;

  if (text && text.length > limit) {
    new_text = text.substring(0, limit) + '...';
  }

  return new_text;
};

/**
 * Translates status using rebranded verbiage
 *
 * @param {string} - Status
 * @return {string} - Rebranded status
 */
const rebrandStatus = (status_code) => {
  const REBRAND_STATUS = {
    'inbrace verifying records': 'Verifying Records',
    'doctor upload new files': 'Upload New Records',
    'doctor upload new records': 'Upload New Records',
    'inbrace preparing setup': 'Preparing Smile Design',
    'inbrace preparing smile design': 'Preparing Smile Design',
    'doctor approve or revise setup': 'Review Smile Design',
    'doctor approve or revise smile design': 'Review Smile Design',
    'inbrace manufacturing case': 'Fabricating Case',
    'case shipped': 'Shipped',
    'inbrace reviewing request': 'Reviewing Request',
    'inbrace manufacturing item request': 'Fabricating Items',
    'inbrace fabricating case': 'Fabricating Case',
    'item request shipped': 'Shipped',
    'item request cancelled': 'Cancelled',
    'item request on hold': 'On Hold',
  };

  if (status_code && REBRAND_STATUS.hasOwnProperty(status_code.toLowerCase())) {
    return REBRAND_STATUS[status_code.toLowerCase()];
  }

  return status_code;
};

const translateLastStatus = (status_code, mode) => {
  mode = mode || 'bpp';
  const last_status_definition = mode === 'bpp' ? getLastStatusDefinition() : getIPPLastStatusDefinition();
  return last_status_definition[status_code] ? last_status_definition[status_code] : status_code;
};

const translateStatusIncomplete = (status_code) => {
  const status_definition = getStatusDefinitionIncomplete();
  return status_definition[status_code] ? status_definition[status_code] : status_code;
};

const translateStatusComplete = (status_code) => {
  const status_definition = getStatusDefinitionComplete();
  return status_definition[status_code] ? status_definition[status_code] : status_code;
};

const translateReviewProcessStatus = (status_code, is_complete) => {
  if (is_complete) {
    return translateStatusComplete(status_code);
  } else {
    return translateStatusIncomplete(status_code);
  }
};

const getCaseShippingAddress = (cases) => {
  return getShippingAddress(cases.delivered_address ? cases.delivered_address : cases.shipping_address);
};

const getShippingAddress = (shipping_address) => {
  let address = '';

  if (shipping_address) {
    address += firstLetterCapital(shipping_address.address_line_1 ? shipping_address.address_line_1.trim() : shipping_address.addr1.trim());

    if (shipping_address.address_line_2 || shipping_address.addr2) {
      address += ' ' + firstLetterCapital(shipping_address.address_line_2 ? shipping_address.address_line_2.trim() : shipping_address.addr2.trim());
    }

    let country = shipping_address.country ? firstLetterCapital(shipping_address.country) : 'United States';
    const isUSA = country.toLowerCase().includes('united states')
    if (isUSA) {
      country = 'United States'
    }

    address += ', ' + firstLetterCapital(shipping_address.city.trim()) + ', ' + shipping_address.state.trim() + ' ' + shipping_address.zip + ', ' + country;
  }

  return address;
};

const getHTMLFormatedShippingAddress = (shipping_address) => {
  let completeAddress = getShippingAddress(shipping_address);
  let parts = completeAddress.split('-');
  let country = parts[parts.length - 1].trim();
  parts.pop();

  let restOfAddress = parts.join('-').trim();

  return restOfAddress + ' <br> ' + country;
};

const getShippingAddressName = (shipping_address) => {
  let addressname = '';

  if (shipping_address) {
    if (shipping_address.address_name) {
      addressname = shipping_address.address_name.trim();
    } else {
      addressname = shipping_address.city.trim() + ' Practice';
    }
  }

  return addressname;
};

const getShippingAddressId = (shipping_address) => {
  let addressId = '';
  if (shipping_address) {
    return shipping_address.id;
  }

  return addressId;
};

const getSortValue = (sort) => {
  return sort ? 'asc' : 'desc';
};

const getSubmissionDateDefaultSort = () => {
  return 'desc';
};

const getTargetShipDateDefaultSort = () => {
  return 'desc';
};

const onReloadPage = () => {
  window.location.reload();
};

const loadAllTeeth = () => {
  const UR = _.range(8, 0, -1).map((item) => 'UR' + item);
  const UL = _.range(1, 9).map((item) => 'UL' + item);
  const LR = _.range(8, 0, -1).map((item) => 'LR' + item);
  const LL = _.range(1, 9).map((item) => 'LL' + item);

  return [].concat(UR).concat(UL).concat(LR).concat(LL);
};

const groupDSOCases = (cases) => {
  let dso_raw = {};

  for (let i = 0; i < cases.length; i++) {
    if (cases[i].alias_name) {
      // Create DSO
      if (!dso_raw[cases[i].alias_name]) {
        dso_raw[cases[i].alias_name] = [];
      }

      dso_raw[cases[i].alias_name].push(cases[i]);
    }
  }

  return dso_raw;
};

const getLastShippedCaseIdFromCaseDetail = (case_details, case_id = '') => {
  const cases = case_details.cases.filter(function (my_case) {
    return my_case.status_code === 'STATUS_SHIP';
  });

  if (cases && cases.length > 0) {
    case_id = cases[cases.length - 1].case_id;
  }

  return case_id;
};

const makePresets = (first, last) => {
  var presets = {};
  _.range(first, last + 1).forEach((x) => {
    presets[x] = makePreset(x);
  });
  return presets;
};

const makePreset = (n) => {
  var teeth = [];
  _.range(1, n + 1).forEach((x) => {
    teeth.push('UL' + x, 'UR' + x, 'LL' + x, 'LR' + x);
  });
  return teeth;
};

/**
 * Determines if selected date is before current date and after 1/1/2010 and
 * support existing code that used the previous style of isValidDate
 * @function
 * @param {String} date - Selected date string
 * @param {String} min_date - Min date string
 * @param {String} max_date - Max date string
 * @return {Boolean} - True or False
 */
const isValidDate = (date, min_date, max_date, allowEmptyDate = true) => {
  let is_valid = true;

  if (date === '') {
    if (allowEmptyDate) {
      return is_valid;
    } else return false;
  }

  if (date) {
    const selected_date = Moment(new Date(date)).utc().add(1, 'days').startOf('day').valueOf(); // converted to utc its always 1 day behind
    const oldest_date = min_date ? Moment(new Date(min_date)).utc().startOf('day').valueOf() : Moment(new Date(2010, 0, 1)).valueOf();
    const current_date = max_date
      ? Moment(new Date(max_date)).utc().add(1, 'days').startOf('day').valueOf()
      : Moment(Moment().format('YYYY-MM-DD')).utc().add(1, 'days').startOf('day').valueOf(); //format the same

    is_valid = selected_date > oldest_date && selected_date <= current_date;
  }

  return is_valid;
};

/**
 * Determines if selected date is before shceduled date and after 1/1/2010
 * @function
 * @param {String} date - Selected date string
 * @return {Boolean} - True or False
 */
const isValidScheduledDate = (date, advancesNum, advancesType) => {
  let is_valid = true;

  if ((date, advancesNum, advancesType)) {
    const selected_date = Moment(new Date(date)).utc().add(1, 'days').startOf('day').valueOf(); // converted to utc its always 1 day behind
    const oldest_date = Moment(new Date(2010, 0, 1)).valueOf();
    const scheduled_date = Moment(Moment().format('YYYY-MM-DD')).utc().add(advancesNum, advancesType).add(1, 'days').valueOf(); //format the same

    is_valid = selected_date > oldest_date && selected_date <= scheduled_date;
  }

  return is_valid;
};

/**
 * Determines if file is after after AFG Launch date
 * @function
 * @param {String} date - Selected date string
 * @return {Boolean} - True or False
 */
const isAfterAFGLaunch = (date) => {
  let is_after = false;

  if (date) {
    const created_date = Moment(new Date(date)).valueOf();
    const afg_launch_date = Moment(new Date(2021, 4, 3)).valueOf();
    is_after = created_date >= afg_launch_date;
  }

  return is_after;
};

/**
 * Determines if segmentation production tx guide form is completed
 * @function
 * @param {Object} questions - Production tx guide questions with current selections
 * @return {Boolean} True or False
 */
const isSegmentationTxGuideFormCompleted = (questions) => {
  for (const category in questions) {
    for (const question in questions[category]) {
      if (questions[category][question].is_selected && questions[category][question].selected_teeth.length === 0) {
        return false;
      }
    }
  }
  return true;
};

const clearZipStatus = (that) => {
  that.setState({
    status_type: '',
    status_message: '',
  });
};

/**
 * Downloads from Dropbox as zip file (takes either a directory or a zip file)
 * @function
 * @param {object} that - Instance of class
 * @param {object} response - Response from zip API containing dbx location info
 * @param {string} filename - Name of file after downloading
 */
const downloadZip = (that, response, filename) => {
  if (response && response.data && response.data.path && process.env.REACT_APP_DROPBOX_READ_ONLY) {
    let dbx = new DropboxInstance({ accessToken: process.env.REACT_APP_DROPBOX_READ_ONLY });

    dbx.filesDownloadZip({ path: response.data.path }).then(function (data) {
      setFileLink(data, filename);
      clearZipStatus(that);
    });
  } else {
    clearZipStatus(that);
  }
};

/**
 * Makes link available after downloading file from dropbox
 * @function
 * @param {object} data - Response from dbx download API
 * @param {object} filename - Name of file after downloading
 */
const setFileLink = (data, filename) => {
  const url = window.URL.createObjectURL(data.fileBlob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

/**
 * Capitalize first letter of all words
 * @function
 * @param string {string} - string to be capitalized
 * @return {string} String with all first letters capitalized
 */
const capitalizeFirstLetters = (string) => {
  return string
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
};

/**
 * Helper - Capitalize first letter of string
 * @param {String} String
 */
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
/**
 * Determines if Smile Design production tx guide form is completed
 * @function
 * @param {Object} questions - Production tx guide questions with current selections
 * @param {String} case_id - Case id
 * @param {String} revision - Revision id
 * @return {Object} Form completion information
 */
const isSmileDesignTxGuideFormCompleted = (questions, case_id, is_tab_view, revision) => {
  let result = {
    is_completed: true,
    incompleted_types: [],
  };
  if (questions.change_treatment_smile_design && !questions.change_treatment_smile_design_selections.selected_teeth) {
    result.is_completed = false;
    result.incompleted_types.push('change_treatment');
  }
  if (!is_tab_view && case_id.includes('-DE') && revision !== 'DE3.0' && !questions.requested_treatment_selections.selected_teeth) {
    result.is_completed = false;
    result.incompleted_types.push('requested_treatment');
  }
  if (questions.inter_arch_collision && !questions.inter_arch_collision_region) {
    result.is_completed = false;
    result.incompleted_types.push('inter_arch_collision_region');
  }
  if (questions.inbrace_ifs_required && !questions.inbrace_ifs_applied) {
    result.is_completed = false;
    result.incompleted_types.push('inbrace_ifs_applied');
  }
  if (
    case_id.includes('-DE') &&
    questions.requested_treatment_selections.selected_teeth &&
    questions.change_treatment_smile_design &&
    questions.change_treatment_smile_design_selections.selected_teeth &&
    orderTeeth(questions.requested_treatment_selections.selected_teeth).join(',') ===
      orderTeeth(questions.change_treatment_smile_design_selections.selected_teeth).join(',') &&
    questions.change_treatment_smile_design_selections.arch_to_treat === questions.requested_treatment_selections.arch_to_treat
  ) {
    result.is_completed = false;
    result.incompleted_types.push('identical_treatment');
  }
  return result;
};

/**
 * Determines if Stage I production tx guide form is completed
 * @function
 * @param {String} case_id = Case id
 * @param {Object} stage_1_questions - Production tx guide questions with current selections
 * @param {String} revision - Tx plan revision
 * @param {Boolean} skip_production_tx_guide - Skip production tx guide indicator
 * @return {Object} Form complete information
 */
const isStage1TxGuideFormCompleted = (case_id, stage_1_questions, smile_design_questions, revision, skip_production_tx_guide = false) => {
  let result = {
    is_completed: true,
    incompleted_types: [],
  };
  if (
    !skip_production_tx_guide &&
    case_id.includes('-DE') &&
    (stage_1_questions.change_treatment_stage_1
      ? !stage_1_questions.change_treatment_stage_1_selections.selected_teeth
      : !stage_1_questions.change_treatment_stage_1) &&
    !smile_design_questions.change_treatment_smile_design_selections.selected_teeth &&
    (revision !== 'DE3.0' ? !smile_design_questions.requested_treatment_selections.selected_teeth : false)
  ) {
    result.is_completed = false;
    result.incompleted_types.push('no_treatment');
  }
  if (!skip_production_tx_guide && stage_1_questions.change_treatment_stage_1 && !stage_1_questions.change_treatment_stage_1_selections.selected_teeth) {
    result.is_completed = false;
    result.incompleted_types.push('change_treatment');
  }
  if (case_id.includes('-DE') && stage_1_questions.wire_selections.length === 0) {
    result.is_completed = false;
    result.incompleted_types.push('wire_selections');
  }
  return result;
};

/**
 * Determines if case has blocking status
 * @function
 * @param {String} case_status - Case status
 * @return {Boolean} True or false
 */
const isCaseBlocked = (case_status) => {
  const blocked_statuses = ['STATUS_HOLD', 'STATUS_CANCEL', 'Doctor Provide Clarification', 'Hold', 'Cancelled'];
  return blocked_statuses.indexOf(case_status) !== -1;
};
/**
 * Get ths Prospect Id from the URL
 * @function
 */
const getSmileIdFromURL = (that) => {
  const pathname = that.props.history && that.props.history.location && that.props.history.location.pathname ? that.props.history.location.pathname : '';
  const last_slash_index = pathname.lastIndexOf('/');
  return last_slash_index >= 0 ? pathname.substr(last_slash_index + 1) : '';
};

/**
 * Captures the case ID from 3matic filename
 * @function
 * @param {String} filename - Filename of uploaded 3matic file
 * @returns {String} The case ID
 */
const getCaseIDFromStageIZip = (filename, gen_2 = false) => {
  const regexp = gen_2 ? /^(\S+)_(3Matic|DigiBP)/g : /^(\S+)_3Matic/g;
  const matches = regexp.exec(filename);
  if (matches) {
    const [, case_id] = Array.from(matches);
    return case_id;
  }
  return false;
};

/**
 * Checks if the file uploaded is a DigiBP file
 * @param {String} filename
 */
const isDigiBpFile = (filename) => {
  const regexp = /^(\S+)_DigiBP/g;
  return regexp.exec(filename);
};
/**
 * Get the earliest date user can input for date input fields
 * @function
 * @returns {String} date 2010-01-01
 */
const getMinDate = () => {
  return Moment('2010-01-01').format('YYYY-MM-DD');
};

/**
 *Get the latest date user can input for date input fields
 * @function
 * @param {Number} [advancesNum] - number of days or months or years to advance
 * @param {String} [advancesType] - should be either years, quarters, months, weeks, days, hours, minutes, seconds, milliseconds
 * @returns {String} the max date
 */
const getMaxDate = (advancesNum, advancesType) => {
  if (advancesNum && advancesType) {
    return Moment().add(advancesNum, advancesType).format('YYYY-MM-DD');
  }
  return Moment().format('YYYY-MM-DD');
};

/**
 * Determines if url is related to dropbox
 * @function
 * @param {String} url - URL string
 * @returns {String} URL string if True, else null
 */
const isDropboxURL = (url) => {
  const isDropboxURL = url && (url.includes('https://content.dropboxapi') || (url.includes('https://') && url.includes('dl.dropbox')));
  return isDropboxURL ? url : null;
};
/**
 * Update to the status in route and state
 *
 * @function
 * @param {Object} event - The event on the change of the status
 */
const getAddressName = (address) => {
  return capitalizeFirstLetter(address.address_name ? address.address_name : `${address.city} Practice`);
};
/**
 * Update to the status in route and state
 *
 * @function
 * @param {Object} event - The event on the change of the status
 */
const sortedAddressByName = (address) => {
  let addressAnnotated = address.map((address) => {
    address['name'] = getAddressName(address);
    return address;
  });

  return _.orderBy(addressAnnotated, ['name'], ['asc']);
};

/**
 * Gets bracket position info
 * @function
 * @param {Object} cases - case details
 * @param {Boolean} collision_included -should collition be included
 * @param {List} ifu_brackets - List of selected brackets from IFU process (not from Form 3420)
 * @return {Array} Array of brackets
 */
const getBracketPositionings = (cases, collision_included, ifu_brackets) => {
  let not_included =
    cases &&
    cases.production_tx_guide &&
    cases.production_tx_guide.stage_1_selected_teeth &&
    cases.production_tx_guide.stage_1_selected_teeth.not_included &&
    !cases.skip_production_tx_guide
      ? cases.production_tx_guide.stage_1_selected_teeth.not_included
      : [];

  if (ifu_brackets && not_included.length === 0) {
    not_included = ifu_brackets.not_included;
  }

  let collisions =
    collision_included &&
    cases &&
    cases.production_tx_guide &&
    cases.production_tx_guide.stage_1_selected_teeth &&
    cases.production_tx_guide.stage_1_selected_teeth.collisions &&
    !cases.skip_production_tx_guide
      ? cases.production_tx_guide.stage_1_selected_teeth.collisions
      : [];

  if (collision_included && collisions.length === 0 && ifu_brackets) {
    collisions = ifu_brackets.collisions;
  }

  let repositioned =
    cases &&
    cases.production_tx_guide &&
    cases.production_tx_guide.stage_1_selected_teeth &&
    cases.production_tx_guide.stage_1_selected_teeth.repositioned &&
    !cases.skip_production_tx_guide
      ? cases.production_tx_guide.stage_1_selected_teeth.repositioned
      : [];

  if (ifu_brackets && repositioned.length === 0) {
    repositioned = ifu_brackets.repositioned;
  }

  if (ifu_brackets) {
    repositioned = repositioned.concat(ifu_brackets.offset_later);
  }

  return _.uniq(_.concat(collisions ?? [], not_included ?? [], repositioned ?? []));
};
/**
 * Get treatment arch
 * @function
 * @return {String} The treated arch
 */
const getArchToTreat = (props) => {
  const prod_arch =
    props &&
    props.cases &&
    !props.cases.skip_production_tx_guide &&
    props.cases.production_tx_guide &&
    props.cases.production_tx_guide.treatment_arch &&
    props.cases.production_tx_guide.treatment_arch.toLowerCase();
  const tx_arch = props && props.cases && props.cases.treatment_arch && props.cases.treatment_arch.toLowerCase();

  return prod_arch ? prod_arch : tx_arch;
};

/**
 * Get guide type
 * @function
 * @param {boolean} - Gen 2.0 indicator
 * @return {string} - Guide type
 */
const getGuideType = (gen_2) => {
  return gen_2 ? 'Adhesive Fill Guide' : 'Bracket Placement Guide';
};

/**
 * Get pdf display name format
 * @function
 * @param {String} case_id - case id
 * @param {Object} patient_info - patient details related to the case
 * @param {String} file_type - type of pdf file
 * @return {String} - pdf display name
 */
const getPDFNameTemplate = (case_id, patient_info, file_type) => {
  const is_ireq = case_id.indexOf('-IR') > 0;
  const identifier = is_ireq ? removeIreqIdInitialNumber(case_id) : removeCaseIdInitialNumber(case_id);
  return `${identifier}_${patient_info?.first_name ?? ''}${patient_info?.last_name?.[0]}_${file_type}`;
};
/**
 * Get first letter of all words in a phrase
 * @function
 * @param {String} phrase - string to be abbreviated
 * @param {String} delimiter - delimiter to seperate words in a phrase
 * @return {String} - Abbreviation
 */
const getAbbreviation = (phrase, delimiter) =>
  phrase
    .split(delimiter)
    .map((word) => word[0])
    .join('');

const getFileService = () => {
  const defaultService = 'Dropbox';
  const service = process.env.REACT_APP_FILE_SERVICE;
  return service ?? defaultService;
};

const isS3FileService = () => getFileService() === 'S3';
const isDropboxFileService = () => getFileService() === 'Dropbox';

const getMimeType = (file_extension) => {
  if (file_extension === 'stl') {
    return 'model/stl';
  } else if (file_extension === 'png') {
    return 'image/png';
  } else if (file_extension === 'jpg' || file_extension === 'jpeg') {
    return 'image/jpeg';
  } else if (file_extension === 'tif' || file_extension === 'tiff') {
    return 'image/tiff';
  } else if (file_extension === 'bmp') {
    return 'image/bmp';
  } else if (file_extension === 'gif') {
    return 'image/gif';
  } else if (file_extension === 'pdf') {
    return 'application/pdf';
  } else if (file_extension === 'xls') {
    return 'application/vnd.ms-excel';
  } else if (file_extension === 'xlsx') {
    return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  } else if (file_extension === 'xlsm') {
    return 'application/vnd.ms-excel.sheet.macroEnabled.12';
  } else if (file_extension === 'zip') {
    return 'application/zip';
  } else {
    return 'unknown';
  }
};

/**
 * Converts html to plain text
 * @function
 * @param {string} text - Given text
 * @returns {string} - Text with html stripped
 */
const convertHtmlToPlainText = (text) => {
  let doc = new DOMParser().parseFromString(text, 'text/html');
  return doc.body.textContent || '';
};

/**
 * Get inbrace requirements from case details
 * @function
 * @param {Object} case_details - Case details
 * @param {String} case_id - Case ID
 * @returns {Boolean} - True if InBrace IFS is required
 */
const getInBraceIfsRequirement = (case_details, case_id) => {
  if (case_details.cases) {
    const current_case = case_details?.cases.filter((c) => {
      return c.case_id === case_id;
    });
    if (current_case?.length) {
      return current_case[0].production_tx_guide?.inbrace_ifs_required;
    }
  } else return case_details?.production_tx_guide?.inbrace_ifs_required;
};

/**
 * Check if case is provider edited
 * @function
 * @param {Object} case_details - Case details
 * @param {String} case_id - Case ID
 * @returns {Boolean} - True if case is provider edited
 */
const isProviderEdited = (case_details, case_id) => {
  if (case_details.cases) {
    const current_case = case_details?.cases.filter((c) => {
      return c.case_id === case_id;
    });
    if (current_case?.length) {
      return current_case[0].provider_edit;
    }
  } else return case_details?.provider_edit;
};


/**
 * This debounceThrottle function can be used to control the rate of firing an event, such as typing in a textarea.
 * 
 * @function
 * @param {Function} func - The function to be debounced and throttled.
 * @param {Number} wait - The time interval in milliseconds to debounce and throttle the function.
 * @returns {Function} - A function that applies debounce and throttle to the given function.
 * @example
 * Imagine we want to make an API request when a user types in a textarea, but we want to limit the rate of requests.
 * We can use debounceThrottle to achieve this:
 * const throttledApiCall = debounceThrottle(apiCallFunction, 300);
 * textareaElement.addEventListener('input', throttledApiCall);
 */
const debounceThrottle = (func, wait) => {
  let timeout;
  let inThrottle;

  return function(...args) {
    const context = this;

    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, wait);
      return;
    }

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

export {
  applyCSSToID,
  capitalizeFirstLetter,
  capitalizeFirstLetters,
  clearZipStatus,
  convertHtmlToPlainText,
  downloadZip,
  getAddressName,
  getArchToTreat,
  getBracketPositionings,
  getBusinessRoleList,
  getBusinessRoleListLowerCase,
  getCaseIDFromStageIZip,
  getLastCaseStatusFromStatusesList,
  getCaseLastStatus,
  getCaseShippingAddress,
  getDsoRoleList,
  getHTMLFormatedShippingAddress,
  getIrStatusPriorityDefinition,
  getLastAssignedTo,
  getLastCADAssignTo,
  getLastShippedCaseIdFromCaseDetail,
  getLastStatusBySelectedCase,
  getShippingAddress,
  getShippingAddressId,
  getShippingAddressName,
  getSmileIdFromURL,
  getSmileStatusPriorityDefinition,
  getSortValue,
  getStatusAlertDefinition,
  getStatusDefinition,
  getStatusPriorityDefinition,
  getSubmissionDateDefaultSort,
  getTargetShipDateDefaultSort,
  groupDSOCases,
  isAfterAFGLaunch,
  isCancelOrHoldNextStatus,
  isCaseBlocked,
  isDigiBpFile,
  isDropboxURL,
  isKnowledgeBaseRoute,
  isValidDate,
  isValidScheduledDate,
  loadAllTeeth,
  makePresets,
  onReloadPage,
  rebrandStatus,
  sortedAddressByName,
  translateCaseStatus,
  translateFilterStatus,
  translateLastStatus,
  translateReviewProcessStatus,
  translateRole,
  translateStatus,
  translateStatusComplete,
  translateStatusIncomplete,
  truncateTextByLimit,
  isSegmentationTxGuideFormCompleted,
  isSmileDesignTxGuideFormCompleted,
  isStage1TxGuideFormCompleted,
  getMaxDate,
  getMinDate,
  getGuideType,
  getPDFNameTemplate,
  getAbbreviation,
  isS3FileService,
  isDropboxFileService,
  getMimeType,
  getInBraceIfsRequirement,
  isProviderEdited,
  debounceThrottle,
};
