// External Libs
import React, { Component } from 'react';

// Internal Libs
import Modal from '../../../../components/modal/modal';

class Upload extends Component {
  render() {
    return (
      <Modal
        preset="upload-pdf"
        header_text={this.props.header_text}
        modal_size_class="modal-lg"
        ireq_id={this.props.ireq_id}
        folder="ws"
        type={this.props.type}
        onUpload={(data) => {
          this.props.onUpload(data);
        }}
        location={this.props.location}
        upload_content={[]}
        onCloseButtonClick={() => {
          this.props.onClose();
        }}
        theme="bpp"
        ws_id={this.props.ws_id}
        wire_name={this.props.wire_name}
        wsg_rev={this.props.wsg_rev}	
      />
    );
  }
}

export default Upload;
